.icon-angle-left {
	width: 597.39px;
	height: 980.62px;
}

.icon-angle-right {
	width: 597.39px;
	height: 980.62px;
}

.icon-cross {
	width: 20px;
	height: 20px;
}

.icon-envelope {
	width: 26px;
	height: 18px;
}

.icon-facebook {
	width: 14.51px;
	height: 27.71px;
}

.icon-instagram {
	width: 28.34px;
	height: 28.34px;
}

.icon-linkedin {
	width: 24.2px;
	height: 24.16px;
}

.icon-marker {
	width: 19px;
	height: 25px;
}

.icon-menu {
	width: 25.5px;
	height: 17px;
}

.icon-phone {
	width: 24px;
	height: 24px;
}

